/*===================Responsive css=======================*/


/*========= For big screen Desktops ===========*/
@media (min-width: 1620px) {
  .lang_ {

    margin-left: 10pc;

  }

}

@media (max-width: 1281px) {

  .privacy_policy p {
    margin-left: 20%;
  }

  .book_image {
    width: auto !important;
  }

  .admin_bt {
    display: block !important;
    background-color: #EA702B !important;
    width: 179px;
    height: 47px !important;
    color: #fff !important;
    text-align: justify !important;
    /* margin-left: 4px; */
  }

  .specilaity_btn {
    width: 255px;
    margin-left: -70px;
    text-align: center !important;
  }


  .adminstartionimg {
    margin-right: 8px !important;
    width: 12%;
  }

  .adminstartionimg {
    margin-right: 5px !important;

  }

  .btnsss {
    width: 100% !important;
    height: 60px;
  }

  .pop-up {
    margin-top: 22px !important;
    margin-left: 0px !important;
  }

  button.btn.premium-btn {
    background: #EA702B;
    /* width: auto; */
  }

  .admin {
    width: 37% !important;

  }
}


@media (max-height: 900px) {


  .custom-button {
    padding: 20px;
    /* width: 176px !important; */
    height: 60px;
    border-radius: 7px !important;
    width: 96% !important;
  }
}


/*========= For Laptops, Desktops screen ===========*/


@media (min-width: 1025px) and (max-width: 1280px) {

  .privacy_policy p {
    margin-left: 8%;
  }

}

@media (min-width: 1234px) and (max-width: 1619px) {

  .upload-video-logo {
    position: initial;
    margin-top: 25px !important;
    /* left: 139px !important; */
  }

}



/*========= For Tablets, Ipads (portrait) screen ===========*/

@media (min-width: 768px) and (max-width: 1024px) {
  .login_by {

    bottom: 25px;

  }

  .admin_bt_mob {
    border-radius: 20px 0 0 0 !important;
  }

  .premium {
    display: none !important;
  }

  .version {
    display: none;
  }

  .lang_ {

    margin-left: 10%;
  }

  /* span.img_set {
   
    top: 50%;
    right: 63%;
   
  } */


}


/*========= Tablets, Ipads (landscape) screen ===========*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .login_by {

    bottom: 25px;

  }

  .admin_bt_mob {
    border-radius: 20px 0 0 0 !important;
  }

  .lang_ {

    margin-left: 10%;
  }

}


/*=========== 883px resolution ================ */
@media (max-width: 883px) {

  .admin {
    width: 37%;
    font-size: 13px !important;
    text-align: center !important;
  }
  
 

}



@media (max-width: 1281px) {

  .upload-video-logo {
    position: relative;
    margin-top: 25px;
    left: 0px;
  }

  .crate-org {
    width: 50% !important;
    margin: auto;
    display: block !important;
  }

  .helpdesk-modal {
    width: 55%;
    margin-top: 0%;
  }
}


@media screen and (max-height: 900px) {
  .version p {
    position: relative !important;
    padding: 0px;
    margin-left: 6px;
    margin-top: 112px;
  }

  .btnsss {
    width: 94% !important;
    height: 60px !important;
    margin-left: 14px !important;
  }

  .adminstartion {
    margin-left: -2px;
    text-align: center;
    display: initial;
    position: absolute;
    margin-top: 0px;
    font-size: 16px;
  }

  .image-admin {
    margin-top: -1px;
    position: relative;
    left: -1px;
  }

  .custom-button-div {
    margin-left: -16px;
  }

  .channel-image {
    position: relative;
    left: 0px !important;
    height: 23px !important;
  }


  .channel-name {
    font-size: 17px;
    margin-left: -32px;
  }







}



/*========= Low Resolution Tablets, Mobiles (Landscape) ===========*/

@media (min-width: 481px) and (max-width: 767px) {
  .login_by {

    bottom: 25px;

  }

  .admin_bt_mob {
    border-radius: 20px 0 0 0 !important;
  }

  /* span.img_set {
      top: 50%;
      right: 65%;
  } */


}

/*========= Most of the Smartphones Mobiles (Portrait) ===========*/

@media (min-width: 320px) and (max-width: 480px) {
  .login_by {

    bottom: 25px;

  }

  .notif {
    width: 252px;

  }

  .admin_bt_mob {
    border-radius: 20px 0 0 0 !important;
  }

  /* span.img_set {
      right: 50%;
      top: 50%;
  } */

  .version p {
    margin-top: 100px;
    font-size: 5px;
    font-weight: 600;
    /* font-family: 'Poppins'; */
    padding: 20px;
    text-align: right;
  }

  .h-custom {
    height: 100%;
  }

  .upcoming-title h2 {
    font-size: 13px;
  }

  .upcoming-head h4 {

    font-size: 18px;

  }

  .categories-list h6 {
    font-size: 13px;


  }

  .lang_ {
    margin-right: 65px;
  }

  .admin_bt {
    display: block !important;
    background-color: #EA702B !important;
    width: auto !important;
    margin-top: 14px !important;
    margin: auto;
    color: #fff !important;
    text-align: justify !important;
  }

}

/*========  for very small screen ===========*/






@media (max-width: 320px) {

  .login_by {

    bottom: 25px;

  }

  .lang_ {
    margin-right: 40px;
  }

  .notif {
    width: 180px;

  }

  span.img_set {
    transform: translate(-108%, 29%);
  }

  .admin_bt_mob {
    border-radius: 20px 0 0 0 !important;
  }

  .admin_bt {

    display: block !important;
    background-color: #EA702B !important;
    width: auto !important;
    margin-top: 14px !important;
    margin: auto;
    color: #fff !important;
    text-align: justify !important;
  }

  .upcoming-title h2 {
    font-size: 12px;
  }
}


/* mobile view css */

@media (min-width: 310px) and (max-width: 450px) {

  .channel-mob {
    display: none !important;
  }

  .pe-20 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .specilaity_btn {
    width: 255px !important;
    margin-left: -70px !important;
    text-align: center !important;
  }

.mob_ch_list{
  margin-top: 20px !important;
}
.for-label {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 17px;
  color: #000000;
}
.categories-list h6 {
  font-size: 12px;
  padding: 4px 18px;
}
.nextsss{
  width: 24px !important;
  height: 24px !important;
  border-radius: 20px !important;
  font-size: 24px;
  line-height: 24px; 
  margin-top: 10px !important;
  text-align: center !important;

}

  .spe .css-1pahdxg-control {
    border-color: hsl(0, 0%, 80%) !important;
    margin-left: -70px !important;
    width: auto !important;
    box-shadow: none !important
  }

  .css-26l3qy-menu {
    margin-top: 0px !important;
    margin-left: -67px !important;
    width: 255px !important;
  }

  .home_caraousel .sidebar-margin {
    padding-left: 42px !important;
    padding-right: 51px !important;
    position: relative;
    width: 100%;
  }

  .helpdesk-modal {
    width: 82%;
    height: 49%;
  }

  .new-btn {
    width: 116px !important;
    height: 35px !important;
    margin-top: 20px;
  }

  ul.dropdown-menu.profile-nav.shadow-sm.rounded.show {
    transform: translate3d(-143px, 51px, 0px) !important;
  }

  .profi {
    margin-left: 11px;
    /* margin-right: 26px; */
  }

  .model_org {
    width: 92% !important;
    height: 74%;
    /* width: -webkit-fill-available; */
  }

  .mob-title {
    margin-left: 0% !important;
  }

  .mob-list-of-users {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .mob-btn {
    height: 58px !important;
  }

  .mob_ch_list {
    margin-top: 20px !important;
  }

  .for-label {
    font-weight: 400;
    font-size: 12px !important;
    line-height: 17px;
    color: #000000;
  }

  div#pdf-controls {
    background-color: #ffffff00 !important;
    box-shadow: none !important;
    display: flex;
    justify-content: flex-end;
    left: 0 !important;
    padding: 8px;
    position: -webkit-sticky;
    position: absolute !important;
    right: 19px !important;
    top: 0px !important;
    z-index: 1;
  }

  .mob_close_btn {
    margin-top: 8px !important;
  }

  .doc-size {
    margin-left: 0px;
  }

  .canvas.react-pdf__Page__canvas {
    user-select: none;
    width: 0px;
    height: 0px;
  }

  .mob_doc_view {
    height: 40vh !important
  }

  .lang_ {
    display: none !important;
  }

  .profile-li1:hover {
    background-color: #ECECEC;
  }

  .profile-li1 {
    display: block;
    position: relative;
    min-height: 60px;
    margin-top: 0px;
    border-bottom: 1px solid #ccc;
    line-height: 16px;
    padding: 21px 0;
  }

  .bookk {
    margin-left: 0% !important;
  }
  .upload-video-logo{
    display: none;
  }
  .image-sizeee {
    height: 22px;
}

}
