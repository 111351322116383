body {
    /* font-family: "Open Sans", sans-serif; */

    background: #F3F5F7 !important;


}

* {
    font-family: 'Poppins';
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

input {
    -webkit-user-select: text;
}

a {
    color: #000 !important;
}

/* search */

@media only screen and (min-width: 992px) {

    .nav_inner_search .search-box {
        width: 400px;
    }
}

.nav_inner_search .search-box {
    margin-top: 0;
}

.search-box .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(17, 16, 16, 0.3);
    font-size: 15px;
}

.search-box .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(17, 16, 16, 0.3);
    ;
}

.search-box .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(17, 16, 16, 0.3);
    ;
}

.search-box .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(17, 16, 16, 0.3);
    ;
}

.search-box .input-group.search_active .input-group-addon.first img:last-child {
    display: block;
}

.search-box .input-group.search_active .input-group-addon.first img:first-child {
    display: none;
}

.search-box .search_active .search_close_icon {
    display: block;
}

.nav_inner_search .search-box,
.saveedNote_rightPop .search-box,
.view_all_note .search-box {
    border: 1px solid rgba(159, 159, 159, 0.7);
    border-radius: 20px;
    height: 40px;
    width: 70%;
    margin: inherit;
    /* margin-top: 16px; */
    /* position: relative; */
    text-align: center;
    margin: auto;

}

.input-group:focus-within {
    border: 2px solid;
    border-radius: 18px !important;
}

input.form-control.input_search:focus-within::placeholder {
    color: black !important;
}

.search-box .input-group .input-group-addon {
    align-items: center;
    background: transparent;
    border: 0;
    display: flex;
    flex-wrap: wrap;
    height: 36px;
    justify-content: space-around;
    padding: 0;
    position: relative;
    text-align: center;
    width: 50px;
}

.search-box .input-group .input-group-addon.first img:last-child {
    display: none;
}

/* .search-box */

li {
    list-style: none
}

a {
    text-decoration: none !important;
}

.clearfix {
    padding-top: 20px;
    padding-bottom: 12px;
}

nav.navbar.navbar-expand-lg.navbar-light {
    height: 73px;
    background: #FFFFFF;
}

.pe-20 {
    padding-right: 2rem !important;
}

/* .has-search.form-control.me-2 {
    border-radius: 20px;
    background: #ECECEC;
    padding: 1px;
    width: 400px;
    height: 40px;
    margin-left: 50%;
    margin-right: 50%;
    margin: auto;
    margin-right: auto;
    margin-left: 156px;
} */
.has-search .form-control {
    /* padding-left: 2.375rem; */
    background: #ececec00;
    border-radius: 20px;
    border: none;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    box-sizing: border-box;

}

.search-box {
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    height: 40px;
}






.navi ul li {
    /* font-size: 20px; */
    list-style-type: none;
    /* box-sizing: border-box; */
    /* position: absolute; */
    /* left: 4.48%; */
    /* right: 5.38%; */
    /* top: 9.14%; */
    /* bottom: 86.03%; */
    background: #FFFFFF;
    border-radius: 10px;
    margin: 10px;
    padding: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 99.3%;
}

.lang_ select {
    /* background: url(../../image/dowmorg.svg) no-repeat 100%; */
    background-size: 20px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 16px;
}


.navi ul li a {
    transition: 0.2s ease-in-out;
}

.navi ul li i {
    width: 25px;
    font-size: 16px;
}

.hide_side {
    /* margin-left: 55px; */
    margin-bottom: 30.17px;
    cursor: pointer;
}

/* sidebar */

.sidebar {
    margin: 0;
    padding: 0;
    margin-left: 15px;
    overflow: hidden;
    overflow-y: auto;
    max-width: 260px;
    height: 706px;

}

.channl_scroll {
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
}


.channl_scroll1 {
    max-height: 300px;
    overflow-y: auto;
    width: 78%;
}
.sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
    border-radius: 10px;
    cursor: pointer;
}

.sidebar a.active {

    border-radius: 10px;
    background-color: #FFF;
    color: black;
    font-size: 16px;
}

.sidebar a:hover {
    background-color: #FFF;

}

.admin_drop.show {
    width: 201px;
    background-color: #f3f5f7;
}

.admin_drop.show:hover {
    width: 204px !important;
    background-color: #f3f5f7;
}

/* end */
.cursor_ {
    cursor: pointer;
}

/* upcoming */

.upcoming-head h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-transform: capitalize;
    line-height: 133.6%;
    padding-bottom: 20px;
    margin-bottom: 0px;
    padding-top: 20px;
}

.upcoming-box {
    position: relative;
    text-align: center;
    background: #000000;
    border: 2px solid #EA702B;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 10px;
}

.upcoming-title h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    line-height: 27px;
    /* margin-bottom: 0px; */
}

.upcoming-title p {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 99.3%;
}

.upcoming-img img {
    width: 42px !important;
    height: 42px;
    border: 2px solid #EA702B;
    border-radius: 300px;
    margin-right: 5px;
}

.upcoming-heading {
    margin-top: 10px;
}

iframe {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 10px;
    border-radius: 10px;
    width: -moz-available;
    height: inherit;
}

.categories-list h6 {
    border: 2px solid #e1e1e1;
    padding: 12px 36px;
    background: #f3f5f7;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
}

.category_active {
    color: #fff;
    background-color: #ea702b !important;
    border: 1px solid !important;
}

.categories-list h6:hover {
    background: #ebebeb;
}

.categories-list {
    display: flex;
}

.trending-boxs {
    position: relative;
    text-align: center;
    overflow: hidden;
    background: #000000;
    border-radius: 12px;
    margin-top: 10px;
    z-index: -1;
}

.trending-boxs img {
    width: -webkit-fill-available;
    width: -moz-available;
    object-fit: contain;
    aspect-ratio: 16 / 9;
}

.trending-img img {
    width: 40px;
    height: 40px;
    border: 2px solid #EA702B;
    border-radius: 300px;
}

.home-trending-img img {
    width: 27px;
    height: 27px;
    border: 2px solid #EA702B;
    border-radius: 300px;
}

.trending-title h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    margin-bottom: 1px;
}

.trending-title p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 99.3%;
}

.dropdown-menu .nikhil {
    position: absolute;
    width: 357px;
    height: 461px;
    left: 3px;
    top: 10px;

    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.togle_::after {
    display: none !important;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.trending-heading.d-flex {
    margin-top: 8px;
    padding-bottom: 20px;
    padding-top: 5px;
}

.premium {
    width: 189px;
    height: 165px;
    left: 17px;
    top: 768px;
    background: #FFFFFF;
    border-radius: 10px;
    /* margin: 0 10px 0; */
    margin-top: 40px;

}

.boxxxx {
    margin-left: 20px;
}

.premium-box h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;

}

.premium-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
}

.premium-box {
    padding: 12px;
    margin-left: 1px;
}

button.btn.premium-btn {
    background: #EA702B;
    width: 161px;
    height: 41px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 10px;
    border: 2px solid #FFFFFF;

}

.filter {
    display: flex;
    justify-content: space-between;
}

p.filter {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 40px;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 0px;
}

p.filter img {
    width: 1.3rem;
    /* height: 12px; */
    /* background: #000000; */
    /* font-size: 52rem; */
}

.notif {
    width: 310px;
    max-height: 385px;
    right: 0px;
    top: 22px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
    border-radius: 10px;
    transform: translate3d(-1px, 33px, 0px) !important;
}

.dropdown-header1 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    color: #000000;
}

.notifiction-list {
    background: #F3F5F7;
    border: 1px solid #EA702B !important;
    border-radius: 10px;
    margin-bottom: 10px;
}

/* .notifiction-list :hover {
    background: #FFFFFF;
} */

.notif-box {
    display: flex;
    /* margin: 10px; */
}

.notif-img img {
    /* overflow: hidden; */
    margin-right: 10px;
    width: 26px;
}

.notif-img {
    border: 2px solid #EA702B;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    margin-right: 10px;
}

.notif-title .title {
    margin: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.notif-title .sub-title {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 99.3%;
    margin-top: 6px;
    /* identical to box height, or 10px */
    color: #000000;
    margin-bottom: 5px;
}

.notif-title span {
    /* margin-top: 6px; */
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 99.3%;
    color: #000000;
}

/* ul.dropdown-menu.dropdown-menu-lg-end.show {
    width: 40rem;
} */

.profile-heading {
    margin: 10px;
}

.profile-heading h3 {
    font-weight: 500;
    font-size: 26px;
    line-height: 72.3%;

}

.profiles {
    margin: 15px;
}

.profiles div {
    margin-bottom: 5px;
}

.filter li span input {

    border-radius: 7px;
    border: 2px solid #ECECEC;
    padding: 10px 13px;
    border: 1px solid #9E9292;
}

.filter li {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
}

.filter_date input {
    text-transform: uppercase;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 133.6%;
    color: #828282;

}

.filter_date i {
    position: relative;
    right: 23px;
    top: 2px;
    font-size: 16px;
    color: #4f5150;
}

.live_ {
    position: relative;
}

.live_ p {
    position: absolute;
    text-transform: uppercase;
    background: #EA702B;
    border: 2px solid #EA702B;
    border-radius: 20px;
    color: #fff;
    width: 58px;
    text-align: center;
    right: 10px;
    top: 8px;
}

.live_e {
    position: relative;
}

.live_e p {
    text-transform: uppercase;
    background: #EA702B;
    border: 2px solid #EA702B;
    border-radius: 20px;
    color: #fff;
    position: absolute;
    width: 58px;
    text-align: center;
    right: 11px;
    top: 12px;
    z-index: 1;
}

.time_ {
    position: relative;
}

.time_ p {
    position: absolute;
    top: -39px;
    background: #D9D4E6;
    border-radius: 20px;
    width: 82px;
    height: 25px;
    text-align: center;
    right: 10px;
}

.time_trend {
    position: relative;
}

.time_trend p {
    position: absolute;
    top: -28px;
    background: #D9D4E6;
    border-radius: 20px;
    width: 63px;
    height: 20px;
    ;
    text-align: center;
    right: 10px;
    font-size: small;
}

.live_trend p {
    text-transform: uppercase;
    background: #EA702B;
    border: 2px solid #EA702B;
    border-radius: 20px;
    color: #fff;
    position: absolute;
    left: 39%;
    width: 58px;
    text-align: center;
    top: 120%;
    margin-top: 8px;
}

.profile_heading h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 133.6%;
    color: #000000;
    text-transform: capitalize;
}

.for-label {
    /* font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000; */

    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px;
    color: #000000;
    word-break: break-word;
}

.for-input input {
    /* border: 2px solid #000000;
    border-radius: 10px; */
    border: 2px solid #E0E0E0;
    border-radius: 10px;
    /* margin-left: -16% !important; */
}

.profile_img p a {
    color: #000;
    margin-left: 26px;
}

.hr-line {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 35px;
}

.btn.upload-btn {
    background: #ECECEC;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #000000;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 49px;
    padding-left: 49px;
}

.profile_img p {
    margin-top: 10px;
}

.profile_img img {
    border: 2px solid #EA702B;
    border-radius: 300px;
    overflow: hidden;
    background-color: #F3CDB8;
    width: 140px;
    height: 140px;
    transition: .5s ease;
    /* margin-left: 38px; */
}

.img_set {
    position: relative;
}

span.img_set {
    transition: .5s ease;
    position: absolute;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    font-weight: 500;
    transform: translate(-121%, 23%);

}

.privacy_policy {
    color: #878787;
    ;
}

.privacy_policy a {
    color: #0d6efd !important;
    text-decoration: underline !important;
}


.pro_outer:hover span.img_set {
    opacity: 0;
    transition: .5s ease;
}

.profile-nav li {
    width: 180px;
    padding-bottom: 20px;
    /* height: 160px; */
    /* margin-top: 7px; */
}

.profi i {
    margin-right: 5px;
    padding: 4px;
    /* font-family: 'Poppins'; */
    font-style: normal;
    /* font-weight: 400; */
    font-size: 18px;
    line-height: 98.3%;
    color: #000000;
}

.profi {
    margin-left: 15px;

    /* margin-right: 26px; */
}

.profile-nav a {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 99.3%;
    color: #000;
}

.profile-nav a :hover {
    color: black;
}

.box {
    margin-top: 30vh;
}

.sidebar_small a {
    /* display: flex; */
    display: block;
    /* background-color: #fff; */
    width: 40px;
    /* margin: 20px; */
    color: black;
    padding: 16px;
}

.show_side {
    /* margin-left: 55px; */
    margin-bottom: 43.17px;
    cursor: pointer;
}

/* .sidebar_small a.active {
    border-radius: 10px;
    background-color: #FFF;
    color: black;
    width: 45px;
    height: 46px;
    margin-bottom: 10px;
    padding: 10px;
} */
.sidebar_small a.active {
    border-radius: 10px;
    background-color: #FFF;
    /* color: black; */
    /* width: 40px
px
; */
    /* height: 46px; */
    /* margin-bottom: 10px; */
    padding: 10px;
    margin-left: 10px;
}

.load_next {
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

.load_next span {
    width: 90px;
    height: 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.025em;
    text-transform: capitalize;
    padding: 10px;
    border: 1px solid #dedede;
    border-radius: 20px;
}

.login {
    margin: 10px;
    padding: 10px;
    border: 1px solid #4f5150;
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.h-custom {
    height: calc(100% - 73px);
}

.login_bx {
    padding: 60px;
    padding-top: 50px !important;
}

button.login-btn.btn.btn-lg {
    background: #eb7c3c;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
}

.sidebar_small_mobile {
    position: fixed;
    bottom: 0;
    background-color: #ececec;
    width: 100%;
    padding: 10px;
    left: 0px;
    z-index: 2;
}

.sidebar_small_mobileee {
    position: fixed;
    bottom: 0;
    background-color: #ececec;
    width: 100%;
    padding: 10px;
    left: 0px;
    z-index: 2;
}




.sidebar_small_mobile a {
    width: 16% !important;
    display: inline-block;
    text-align: center;

}

.sidebar_small_mobileee a {
    width: 16% !important;
    display: inline-block;
    text-align: center;

}


.sidebar_small_mobileee a.active {
    border-radius: 10px;
    background-color: #FFF;
    padding: 10px;
}






.sidebar_small_mobile a.active {
    border-radius: 10px;
    background-color: #FFF;
    padding: 10px;
}

.sticky {
    position: sticky;
    /* z-index: auto; */
    top: 75px;
}



.version p {
    position: fixed;
    margin: auto;
    bottom: 0px;
    left: 21px;
    font-size: 10px;
    font-weight: 600;
    width: 180px;
    padding: 20px;
    text-align: left;

}

.versionss p {
    position: fixed;
    margin: auto;
    bottom: 0px;
    right: 24px;
    font-size: 10px;
    font-weight: 600;
    width: 180px;
    padding: 20px;
    text-align: left;
}









.version span {

    margin: auto;
    bottom: 0px;
    left: 21px;
    font-size: 10px;
    font-weight: 600;
    width: 191px;
    /* padding: 20px; */
    text-align: left;

}

.upcoming-box img {
    width: -webkit-fill-available;
    width: -moz-available;
    object-fit: contain;
    aspect-ratio: 16 / 9;
}

#my-video {
    width: 100% !important;
    height: 100% !important;

}

.infinite-scroll-component {
    overflow: hidden !important;
}

input.pro_inner {
    position: absolute;
    width: 112px;
    opacity: 0;
    top: 29px;
    height: 100px;
    cursor: pointer;
    left: 19px;
}

.pro_outer {
    position: relative;
}

.spinner-border {
    animation: 1.89s linear infinite spinner-border !important;
}

svg.bi.bi-upload {
    color: #ea702b;
    margin-left: 1px !important;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 100%;
    transform: translate(178%, -286%);
    -ms-transform: translate(178%, -286%);
    text-align: center;
    cursor: pointer;
}

.pro_outer:hover .img_set {
    opacity: 0.3;
}

.pro_outer:hover .middle {
    opacity: 1;
}

.play_ svg {
    position: absolute;
    margin: auto;
    opacity: 0;
    top: 0;
    color: #fff;
    right: 0;
    left: 0;
    bottom: 0;
}

.trending-boxs:hover .play_ svg {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    opacity: 1;
}

.trending-boxs:hover:before {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.48);
    transition: all 1s;
    -webkit-transition: all 1s;

}

.upcoming-box:hover .play_ svg {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    opacity: 1;
}

.upcoming-box:hover:before {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.48);
    transition: all 1s;
    -webkit-transition: all 1s;

}

.sign_ {
    color: #eb7c3c !important;
    float: right;
    text-align: center;
    font-weight: 600;
    margin-top: 5px;
    font-size: 13px;
    font-family: 'Poppins';
}

.log_nav {
    padding: 22px 0 20px 0;
    border-bottom: 2px solid #eb7c3c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.page_ {
    text-align: center;
    padding: 80px 0;
}

.page_ h4 {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 40px;
    line-height: 30px;
}

.outline-btn {
    width: 202px;
    height: 57px;
    border: 2px solid #eb7c3c;
    border-radius: 50px;
    font-size: 15px;
    color: #fff !important;
    display: inline-block;
    line-height: 50px;
    text-align: center;
    background: #eb7c3c;
    font-family: 'Poppins', sans-serif;
}

.\34 04error {
    text-align: center;
    font-size: 6rem;
    color: #838383;
    margin-right: 32px;
    margin-bottom: -67px;
}

.line_ {
    border-top: 2px solid #eb7c3c;
}

.gap_ {
    padding: 20vh 0;
}

.pHieT .ellipsis {
    white-space: normal !important;

}

.overlay {
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #00000082;
}

.sc-eCYdqJ.bdHMCv {
    z-index: 2;
}

.cross {
    position: relative;
    /* height: 603px; */
}

.cross_btn {
    position: absolute;
    z-index: 1;
    right: 10px;
    border-radius: 10px;
    padding: 3px;
    top: 10px;
}

.cross_btn button {
    text-transform: capitalize;
    background: #EA702B;
    color: #fff;
    font-weight: 400;
    margin: 6px;
    font-size: 12px;
    text-align: center;
}

.icon_name {
    width: 40px;
    border-radius: 50%;
    text-transform: capitalize;
    height: 40px;
    margin: auto;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    color: #EA702B;
    text-align: center;
    background-color: #ea702b52;
}

.lang_ {
    position: relative;
    margin: auto;
    text-align: center;
}

.lang1_ {
    position: relative;
    margin: auto;
    text-align: center;
}


.css-b62m3t-container {
    position: absolute !important;

    width: 100%;
}

#react-select-5-listbox {
    margin-top: 0px !important;

}

#react-select-11-listbox {
    margin-top: 0px !important;
}

.css-26l3qy-menu {
    margin-top: 0px !important;
    margin-left: -67px !important;
    width: 255px !important;
}

.lang_ select {
    padding: 8px 25px 8px 38px;
    border-radius: 20px;
    color: #000000b3;
    font-size: 13px;
    border-radius: 20px;
    color: #ea702b;
    padding: 0px 32px 0 20px;
    height: 40px;
    margin-bottom: 0px;
    box-shadow: none;
    border: 1px solid #ccc;
}


.lang1_ select {
    padding: 8px 25px 8px 38px;
    border-radius: 20px;
    color: #000000b3;
    font-size: 13px;
    border-radius: 20px;
    color: #ea702b;
    padding: 0px 32px 0 20px;
    height: 43px;
    margin-bottom: 0px;
    box-shadow: none;
    border: 2px solid ;
    background-color: #f1f1f1;
}



.lang_ :hover {
    cursor: pointer;
    box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px 0px;
}

.lang1_ :hover {
    cursor: pointer;
    box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px 0px;
}


.lang_ select option {
    background-color: #ffffff;
    color: #000;
}


.lang1_ select option {
    background-color: #ffffff;
    color: #000;
}

.no_data h2 {
    /* font-size: 0.8rem; */
    font-size: 20px;
    text-align: center;
    margin: auto;
    margin-top: 30vh;
    color: #585a5c;

}

.no_datas h6 {
    font-size: 1.6rem;
    text-align: center;
    margin: auto;
    color: #585A5C;
}

.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    /* display: none; */
}

button.close {
    float: right;
    background-color: #EA702B;
    color: #fff;
    /* border-radius: 6px; */
    width: 25px;
    margin-right: -6px;
    height: 25px;

}

.mymodal2 {
    overflow: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    height: 80vh;
}

li.page-item.active a {
    color: #fff !important;
}

.table>:not(:last-child)>:last-child>* {
    font-size: 13px !important;
}

.table-bordered>:not(caption)>*>* {

    font-size: 12px !important;
}

.admin_bt {
    display: block !important;
    background-color: #EA702B !important;
    width: 179px;
    height: 40px;
    color: #fff !important;
    text-align: justify !important;
    margin-left: auto;
}

.nav_tab {
    background-color: #c5c5c542;
    border-bottom: 1px solid #DEE2E6;
}

.nav_li:hover {
    border-color: #E9ECEF #E9ECEF #DEE2E6 !important;
    background-color: #F1F1F1 !important;
}

ul.pagination {
    z-index: 0;
}

.mob_admin {
    position: absolute !important;
    bottom: 65px;
    right: 0;
}


ul.dropdown-menu.profile-nav.shadow-sm.rounded.show {
    transform: translate3d(-138px, 53px, 0px) !important;
}

.sc-gsnTZi.pHieT svg {

    visibility: hidden;
}


/* .loader-container {
    width: 100%;
    height: 100vh;
    position: relative;
    background: #fff url("../image/akunah.gif") center no-repeat;
    z-index: 1;
} */

.spinner_img img {
    width: 150px;

}

.spinner_img {
    text-align: center;
    height: 100vh;
    background-color: #fff;
    padding-top: 21rem;
    /* padding: 39vh; */

}

.spin_load {
    background-color: #eb7c3c;
    color: #eb7c3c;
}

.translate-middles {
    transform: translate(-110%, -12%) !important;
}

.keclock_img {
    position: fixed;
    z-index: -2;
}

.border_line {
    border-right: 1px solid;
}

.model_org {
    width: 47% !important;
    /* width: -webkit-fill-available; */
}

.btn_org {
    background-color: #EA702B !important;
    color: #fff !important;
}

.btn_org_upload {
    float: right;
    margin-top: 50px;
}

.profile_request {
    border: 2px solid #bec1c3;
    margin-top: 14rem;
    padding: 15px;
}

.cursor {
    cursor: pointer;
}

.like_ {
    border: 2px solid #e1e1e1;
    padding: 7px 15px;
    background: #f3f5f7;
    border-radius: 50px;
    font-style: normal;
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
    width: 100%;
}

.vid_para p {
    font-size: 10px;
    margin-top: 5px;
    color: #16202cc7;
}



.text_black {
    color: #030303;
    font-weight: 500;
    font-size: 10px;
}

.vid_category {
    color: #51555ac7;
    font-size: 13px;
    text-transform: capitalize;
}

.vid_category:hover {
    color: #000;
    font-size: 13px;
    text-transform: capitalize;
}

.side_heading h4 {
    font-size: 14px;
}

.side_heading p {
    font-size: 11px;
    margin: auto;
}

.side-img img {
    width: 30px;
    height: 31px;
    border: 2px solid #EA702B;
    border-radius: 300px;
}

.side_time_trend {
    position: relative;
}

.side_time_trend p {
    position: absolute;
    top: -21px;
    background: #D9D4E6;
    border-radius: 20px;
    width: 53px;
    font-size: 11px;
    height: 17px;
    text-align: center;
    right: 7px;
}

.login_by {
    position: fixed;
    margin: auto;
    bottom: 25px;
    left: 0px;
    right: 0;
    width: 15%;
    font-size: 10px;
    font-weight: 600;
    padding: 20px;
}

.login_by h6 {
    font-size: 13px;
    font-weight: 600;
}

.justifyContentCenter {
    justify-content: center;
}

button.float-end.bg-danger.text-white.border-0 {
    background-color: #EB7C3C !important;
    /* border-radius: 5px; */
    width: 30px;
    height: 30px;
}

.owl-dots {
    display: flex;
    justify-content: center;
}

.scroll_ {
    height: 299px;
    overflow: auto;
}

.vid_move {
    position: sticky;

    top: 75px;
}

.dWgWFX .selected {
    background-color: #eb7c3c !important;
    color: #fff;
}

.featured_txt {
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    padding: 5px;
    font-size: 11px;
    right: 0px;
    top: 0px;
    position: absolute;
    float: right;
    background-color: #EB7C3C;
}

.spec_h {
    overflow: auto;
    height: 330px;
}

button.btn.btn_cookies {
    background-color: #fff;
}

.link {
    color: #EA702B !important;
    text-decoration: none !important;
}

.link:hover {
    text-decoration: underline !important;
}

.cookie-banner {
    align-items: flex-start;
    background: #26262B;
    border-radius: 10px;
    bottom: 12px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 50%;
    max-width: 722px;
    padding: 12px 16px;
    position: fixed;
    transform: translateX(-50%);
    width: calc(100% - 24px);
    z-index: 10;
}

div#react-select-13-listbox {
    margin: auto;
}

div#react-select-3-listbox {
    margin: auto;

}

div#react-select-3-listbox {
    z-index: 2;
}

span.react-loading-skeleton {
    z-index: -1;
}

span.text-primary_ {
    border: 1px solid;
    color: #343333;
    padding: 4px;
    font-size: 12px;
    max-width: 100px;
    display: inline-flex;
    margin: 2px;
    border-radius: 4px;
    width: 90px;
}

span.text-primary_:hover {
    color: #ffffff;
    background-color: #eb7c3c;
    border: 1px solid;
    padding: 4px;
    font-size: 12px;
    display: inline-flex;
    margin: 2px;
    border-radius: 4px;
}



.custom_check_box [type="checkbox"] {
    position: absolute;
    opacity: 0;
}

.custom_check_box [type="checkbox"]+label {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    /* color: #3f2783; */
}

.custom_check_box.large [type="checkbox"]+label {
    padding-left: 40px;
    padding-right: 40px;
    line-height: 25px;
}

.custom_check_box [type="checkbox"]+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 21px;
    height: 21px;
    border: 1px solid rgb(235 125 24 / 70%);
    border-radius: 100%;
    background: #fff;
    margin-left: 15px;
}

.custom_check_box.large [type="checkbox"]+label:before {
    width: 24px;
    height: 24px;
}

.custom_check_box [type="checkbox"]:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #e68507;
    position: absolute;
    top: 5px;
    left: 6px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    margin-left: 15px;
}

.custom_check_box.tick [type="checkbox"]:checked+label:after {


    /* http://localhost:3000/Assets/css/Assets/image/check.png */

    /* background: url(../image/check.png) no-repeat; */
    background-color: #ea702b;
    background-position: center;
    width: 18px;
    height: 18px;
    top: 1px;
    left: 1px;
}

.custom_check_box.tick.large [type="checkbox"]:checked+label:after {
    width: 22px;
    height: 22px;
}


.label {
    margin-top: 15px;
    margin-left: -14px;
}

.form-group.has-search {
    width: 100%;
    margin: auto;
}

.trending-img span {
    font-size: 20px;
}



.update-button {
    margin-top: 10px;
    background: #e9ecef;
    width: 161px !important;
    height: 41px !important;
    /* border-radius: 8px; */
    font-style: normal;

    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: black;
    font-family: 'Poppins';
    height: 50px;
    width: 93%;
    border: 2px solid #f0ecec;
    border-radius: 10px;
}

.edit {
    margin-left: -1%;
}

.divider {
    height: 2px;
    width: 58%;
    color: black;
}

.switchhh {
    box-sizing: border-box;

    position: absolute;
    /* left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%; */

    /* Primary */

    background: #F79246;
    /* Primary */

    border: 2px solid #F79246;
    border-radius: 52px;
    left: 40%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* Colors / White */

    background: #FFFFFF;
    /* Primary */

    border: 2px solid #F79246;
    border-radius: 52px;
}

.switchhh1 {
    /* box-sizing: border-box; */

    position: relative;
    /* left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    
  
    
    background: #F79246;

    
    border: 2px solid #F79246;
    border-radius: 52px */
}



.navv {
    border-radius: 20px;
}

.navs {
    border-radius: 15px;
}

.video-list-tab {
    margin-bottom: 5px;
}

.admin {
    width: 30% !important;
    text-align: center !important;
}

.butttonn {
    background-color: rgb(235, 124, 60);
    margin-left: 19px;
    margin-top: 5px;


}

.button-color {
    border: 1px solid #5e5b5a !important;
    /* margin-left: 21px; */
}

.button-color:hover {
    background-color: rgb(235, 124, 60);
    border: 1px solid #5e5b5a;
    color: #fff !important;
}

.text-color {
    color: #eb7c3c;
}

.text-dangerous{
    color: red;
}

.manageorg {
    width: 22% !important;
    text-align: center !important;

}

.tags {
    /* margin-left: 60%; */
    margin-top: 0px;
    font-size: 18px;
}

.tags-button {
    margin-left: -34%;
}

.manage-plans {
    margin-left: -34%
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {

    border-radius: 28px;
}

.hrr {
    border: 5px !important;
}

.profile {
    border-color: white;
    border-radius: 20px;
}

.custom-card {
    border: 0 !important;
    border-radius: 13px !important;
}

.butttonn {
    margin-left: 7% !important;
}

.btt {
    width: 57%;
}

.page-item.active .page-link {

    background-color: #ea702b !important;
    border-color: #ea702b !important;
}

.trending-page {
    width: 101px;
    height: 29px;
    left: 257px;
    top: 683px;
    font-weight: 600;
    font-size: 22px;
}

.fill {
    margin-right: 22px !important
}

.btts {
    width: 100%;
}

.handbook {
    margin-right: 52px !important;
    border-radius: 37px !important;
}

.adminstartion {
    margin-left: -2px;
    text-align: center;
    display: initial;
}

.recently {
    position: absolute;
    margin-left: 34px;
    direction: initial;
    margin-top: -23px;
}

.recently-video {
    width: 72%;
}

.recee {
    margin-left: 35px;
    display: block;
    top: 45%;
}

.recent-image {
    margin-left: 4px
}

.bok_img {
    width: 24px;
    height: 20px;
}

.book_image {
    width: 240px !important;
}

.video-img {
    width: 28px;
    height: 26px;
    margin-left: -3px;
}

.modal-backdropa {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    /* height: 100vh; */
    background-color: #000;
}

.helpdesk-modal {
    /* width: 50%; */
    width: 37%;
    margin-top: 0%;
}

.heldesk-body {
    /* margin-left: 15% !important; */
}

.doc-size {
    margin-left: 100px;
}

.help-border {
    border-radius: 1px;
}

/* .hellppp{
    margin-left: 9% !important;
} */

.bookk {
    margin-left: 4% !important;
}

.profile-image-size {
    margin-top: 7px;
}

.join-org {
    margin-left: -12% !important;
    margin-top: 3% !important;
}

.create-org {
    margin-left: 66% !important;
}

.button-size {

    height: 47px !important;
}


.sideee {
    margin-top: -12px;
}

.form-control:focus {

    border-color: #f3f5f7 !important;

    box-shadow: none !important;

}

.statics {
    margin-left: 10px;
}

.btns {
    border-radius: 0.25rem !important;
}

button:focus:not(:focus-visible) {

    box-shadow: none !important;

}

.profiless {
    background-color: white;
}

.lang_ :hover {

    box-shadow: none !important;
}
.lang1_ :hover {

    box-shadow: none !important;
}

.btn-secondary {

    border-color: white !important;
}

.width_top1 {
    width: 20%;
}

.width_top2 {
    width: 100%;
}

.width_top3 {
    width: 39%;
}

.pop-up {
    margin-top: 22px !important;
    margin-left: -39px !important;
}

.crate-org {
    width: 100% !important;
}

.toggle {
    --width: 40px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);

    display: inline-block;
    cursor: pointer;
}

.toggle__input {
    display: none;
}

.toggle__fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: #dddddd;
    transition: background 0.2s;
}

.toggle__fill::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: var(--height);
    width: var(--height);
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: var(--border-radius);
    transition: transform 0.2s;
}

.toggle__input:checked~.toggle__fill {
    background: #F79246;
    border: -10px solid #F79246 !important
}

.toggle__input:checked~.toggle__fill::after {
    transform: translateX(var(--height));
}

.toggles {
    margin-left: 10px !important;
    color: white
}

.togglessss {
    position: absolute;
    margin-top: -2px;
    margin-left: 9px !important;
    border: 1px !important;
    color: #808080a6;
}

.alighing {
    text-align: center !important;
}

.inputt {
    border-radius: 10px !important;
}

.new-btn {
    width: 122px !important;
    height: 35px !important;
    margin-top: 4px;
}

.new-btn1 {
    width: 166px !important;
    height: 35px !important;
    margin-top: 4px;
}

.footers {
    border-top: none !important;
}

.error-color {
    color: red;
}

.sidebar-margin {
    margin-top: 10px !important;
}

.sidebarrrr {
    margin-top: 20px;
}

.btnsss {
    width: auto !important;
    margin-left: 12px !important;
}

.adminstartionimg {
    margin-right: 15px !important;

}

.updatess-button {
    margin-top: 10px;
    background: #f1f4f8;
    width: 161px !important;
    height: 41px !important;
    /* border-radius: 8px; */
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.025em;
    text-transform: uppercase;
    color: #878080;
    font-family: 'Poppins';
    height: 50px;
    width: 93%;
    border: 2px solid #f0ecec;
    border-radius: 10px;
}

.avatarss {
    width: 40px;
    height: 40px;
    line-height: initial;
    text-align: center;
    color: rgb(234, 112, 43);
    background: rgb(243, 205, 184);
    border-radius: 100%;

}

.avatar-text {


    margin-left: 3px;
    margin-top: 2px;
}

#file-name {
    display: none;
}

.language {
    border-radius: 20px !important;
}


.text-color-grey {
    color: #595f64
}

a#pdf-download {
    display: none;
}

div#pdf-controls {
    background-color: #ffffff00 !important;
    box-shadow: none !important;
    display: flex;
    justify-content: flex-end;
    left: 0 !important;
    padding: 8px;
    position: -webkit-sticky;
    position: absolute !important;
    right: 33px !important;
    top: -56px !important;
    z-index: 1;
}

.btn-closed {
    margin-top: 17px !important
}

.has-search :focus-within {
    box-shadow: none !important;
}

.has-search .wrapper:hover {
    box-shadow: none !important;
}

ul.dropdown-menu.dropdown-menu-lg-end.profile-nav.shadow-sm.rounded.show {
    border: none;
    background-color: #fff;
    border-left-style: none !important;
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px !important;
    border-radius: 10px !important;
    inset: 15px auto auto 23px !important;
}


ul.dropdown-menu.dropdown-menu-lg-end.profile-nav.shadow-sm.rounded.show:after {
    content: " ";
    position: absolute;
    right: 10px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid white;
}



ul.dropdown-menu.dropdown-menu-lg-end.profile-nav.shadow-sm.rounded.show:after {
    content: " ";
    position: absolute;
    right: 24px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid white;
}



select.language:focus-visible {
    border: 1px solid #ccc !important;
    outline: none !important;
}


.filter_date input:focus-visible {
    border: 1px solid #ccc !important;
    outline: none !important;
}

.arrow_top {
    display: none;
    border: none;
    background-color: #fff;
    border-left-style: none !important;
    box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px !important;
    border-radius: 10px !important;
    inset: 19px auto auto 4px !important;
}

.arrow_top:after {
    content: " ";
    position: absolute;
    right: 13px;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid white;
}

.btn:focus {
    outline: 0;
    box-shadow: none !important
}

.image-sizeee {
    height: 32px;
}

.page-link:focus {

    box-shadow: none !important;
}

.crate-org {
    /* width: 92% !important; */
    width: 30% !important;
    margin: auto;
    display: block !important;
}

.chip {

    background: #ea702b !important;

}

.highlightOption {
    background: #ea702b !important;

}

.multiSelectContainer li:hover {
    background: #ea702b !important;

}

.page-item.active .page-link {

    border-color: #cdcdcd !important;
}

.page-item.active .page-link {
    background-color: #ffffff !important;
    border-bottom: 2px solid #ea702b !important;
    color: #000 !important;

}

a.page-link {
    border: none !important;
}

ul.pagination {
    border: 1px solid #ccc;
    z-index: 0;
    border-radius: 18px;
    padding: 4px;
}

td.cursor {
    text-align: center;
}

.form-check {

    padding-left: 1em !important;

}

.btn-closed {
    margin-top: 24px !important;
}

.css-14el2xx-placeholder {
    font-size: 12px !important
}


.form-select option:hover {
    background-color: #ea702b !important;
}


.fs-12 {
    font-size: 12px !important;
    font-weight: 600;
}

.fw-600 {
    font-weight: 600;

}

/* .width_top1 {
    width: 12%;
    margin-left: 30px;
} */

.width_top1 {
    width: 180px;
    margin-left: 0px;
}

/* .home_caraousel .width_top2{
    width: calc(100% - 180px);
} */

.home_caraousel .owl-carousel.owl-theme.fs-1.owl-loaded.owl-drag {
    position: relative;

}

.home_caraousel .owl-nav {
    position: absolute;

    width: 100%;
    top: -28px;
}

.home_caraousel button.owl-prev {
    position: absolute;
    left: -50px;
    margin-top: 10px !important;
}

.sidebar.sideee a img {
    margin-top: -3px;
    padding-right: 4px;
    margin-left: 6px;
}



.home_caraousel button.owl-next {
    right: -51px;
    position: absolute;
    margin-top: 10px !important;
}

/* .width_top3 {
    width: 13%;
    margin-right: 50px;
} */

.width_top3 {
    width: 180px;
    margin-right: 0px;
    margin-left: auto;
}

.home_caraousel .sidebar-margin {
    position: relative;
    /* border: 1px solid red; */
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}


.vid_title.upcoming-head {
    margin-top: 12px;
}



.categories-title.sidebarrrr.upcoming_caraousel {
    position: relative;
    width: 100%;
    margin-left: 0px;
    /* border: 1px solid red; */
    padding-left: 25px;
    padding-right: 50px;
    margin-right: 0px;
}


.upcoming_caraousel .owl-carousel.owl-theme.fs-1.owl-loaded.owl-drag {
    position: relative;

}

.upcoming_caraousel .owl-nav {
    position: absolute;

    width: 100%;
    top: -28px;
}

.upcoming_caraousel button.owl-prev {
    position: absolute;
    left: -41px;
    margin-top: 10px !important;
}


.upcoming_caraousel button.owl-next {
    right: -40px;
    position: absolute;
    margin-top: 10px !important;
}

button.owl-prev {
    color: #1e1d1c !important;
    background-color: #f3f5f7 !important;
}

button.owl-next {
    color: #121111 !important;
    background-color: #f3f5f7 !important;
}

.css-1s2u09g-control {
    width: 256px !important;
    margin-left: -70px !important;
}

div#react-select-5-listbox {
    width: 107% !important;
}

.nextsss {
    width: 32px !important;
    height: 40px !important;
    border-radius: 20px !important;
    font-size: 35px;
    line-height: 43px;
    margin-top: 9px !important;
    text-align: center !important;

}

/* .nextsss:hover {
    background-color: #c3c6c98f !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 20px !important;
    font-size: 24px;
    line-height: 24px; 
    margin-top: 10px !important;
    text-align: center !important;

} */

.nextsss:hover {
    background-color: #c3c6c98f!important;
    font-size: 24px;
    height: 37px!important;
    line-height: 39px;
    margin-top: 10px!important;
    width: 40px!important;
}

.owl-carousel .owl-item:first-child h6 {
    color: #fff;
    background-color: #ea702b;
    border: 2px solid #ea702b;
}

.owl-carousel .owl-item:first-child h6 a {
    color: #fff !important;
}

.vid_title.upcoming-head.mt-3 a {
    padding-right: 10px;
}

.css-1pahdxg-control {
    width: 100% !important;
}

.scrolling {
    overflow: scroll;
    height: 400px
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
    background-color: #f8f9fa;
}


.upload-video-logo {
    position: relative;
    margin-top: 25px;
    left: 139px;
}

.time_trends {
    background-color: #ea702b;
    width: 16%;
    border-radius: 12px;
}

.live_txttt {
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;
    padding: 7px;
    font-size: 9px;
    left: 11px;
    top: 14px;
    border-radius: 13px;
    position: absolute;
    float: left;
    background-color: #EB7C3C;
    width: 56px;
}

.profile-li:hover {
    background-color: #ECECEC;
}

.profile-li {
    display: block;
    position: relative;
    min-height: 60px;
    margin-top: 0px;
    border-bottom: 1px solid #ccc;
    line-height: 60px;
    padding: 21px 0;
}

li:last-child {
    border-bottom: none;
}

.date-hover:focus-within {
    background-color: #ECECEC;
}

.form-select:focus {
    border-color: #f2f2f2 !important;
    outline: 0 !important;
    box-shadow: none !important
}

.MuiStack-root.css-yzvzqw-MuiStack-root {
    width: 100% !important;

}



.sidebar-upload {
    border: 1px solid !important;
}

.bg-SOME_NAME {
    background-color: #123445 !important;
}

.progressbar {
    text-align: -webkit-right;
    margin-top: 5px;
    /* margin-left: -28px; */
}

.sidebar-upload-video {
    border: 1px solid #ea702b !important;
    margin-top: 6px;
}

/* .sidebar-upload-video:active {
    background-color: #ea702b !important;
} */

a.sidebar-upload-video.active {
    background-color: #FFF;
    font-weight: 600;
}

.yesnobutton {
    background-color: #ff5e00 !important;
    border-color: white;
    border-radius: 8px !important;
    width: 30%;
}

.modal-footer {
    justify-content: center !important;
}

.custom-button {
    padding: 10px;
    /* width: 176px !important; */
    height: 60px;
    border-radius: 9px !important;
    width: 96% !important;
}


.custom-button-div {
    margin-left: -11px;
}

.css-feqhe6 {
    width: 94% !important;
}


.progress {
    width: 100% !important;
}

.prg {
    width: 50%;
    border-radius: 6px;
    border: 1px solid #f1eaea;
    height: 32px;
    padding: 3px;
    background-color: #eb7c3c;
}

.spinner-list {
    position: absolute !important;
    left: 50%;
}

.multiSelectContainer input {
    border: none !important;
}

.accordion-body {
    max-height: 400px !important;
    overflow-y: auto;
}

.accordion-button:not(.collapsed) {
    color: black;
    background-color: #f3f5f7;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../../../public/Assets/image/arrow-image.png)
}

.placeholder-design::placeholder {
    font-size: 12px !important;
}

.channel-drop {
    position: relative;
    text-align: center;
    background-color: #ea702b;
    border-radius: 5px;
}

.channel-name {
    font-size: 17px;
    margin-left: -92px
}

.channel-name1 {
    font-size: 17px;
    margin-left: 45px;
}

.dropdown-toggle::after {
    color: white;
    position: relative;
    left: 2px;
}

.dropdown-toggle1::after {
    color: white;
    position: relative;
    left: -3px;
}


.btnnnns {
    background-color: #EA702B;
    color: #fff;
    width: 77%;
    border-radius: 6px;
    height: 57px;
    margin-left: -2px;
}

.drop {
    position: relative;
    left: -177px;
}

.drop2 {
    position: relative;
    /* left: 26px; */
}

.drop3 {
    position: relative;
    /* left: 26px; */
}

.drop1 {
    position: relative;
    left: 5px;
    margin-top: 9px;
    width: 128%;
}




button.btn.btnnnns.dropdown-toggle:hover {
    color: white;
}

.channel-name {}

.parent-class a:hover {
    background-color: #d4d8da !important;
}


.parent-class li a {
    padding: 10px;
    width: 100% !important;
}



.channels-class {
    /* right: 204px; */
}

.channels-class2 {
    right: 177px;
}




/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
}



.parent-class a:hover {
    background-color: #c0c0c0;
}

.not-found-data {
    left: 0;
    margin-top: 8px;
    position: absolute;
    right: 0;
    text-align: center;
}

.desc {
    position: relative;
    top: -13px;
    left: 3px;
}

.not-found-data2 {
    left: 0;
    margin-top: 8px;
    position: absolute;
    right: 0;
    text-align: center;
}

.not-found-data3 {
    left: 0;
    margin-top: 8px;
    position: absolute;
    right: 0;
    text-align: center;
}

.table-scroll {
    max-height: 60vh;
}

.css-eg2qoq {
    display: block;
    /* flex-direction: column; */
    max-width: 400px;
}

.not-found-data3 {

    position: relative;
    left: 130%
}

.not-found-data4 {
    left: 0;
    margin-top: 8px;
    position: absolute;
    right: 0;
    text-align: center;
}

.channel-image {
    position: relative;
    /* left: -90px; */
    height: 23px !important;
}

.channel-image1 {
    position: relative;
    left: 12px;
    height: 23px !important;
}

.max-height-select {
    max-height: 200px;
    /* Set the maximum height you want */
    overflow-y: auto;
    /* Enable vertical scrollbar when the options exceed the height */
}

.image-admin {
    margin-top: -5px;

}

.patient_btt {
    width: 60%
}

.create-modal {
    position: relative !important;
    border: none !important;
}

.date-picker-width {
    width: 100% !important;
}

.req-img {
    width: 19px;
    height: 18px;
    margin-left: -3px;
}

.mxw_25 {
    max-width: 30%;
}

.mxw_75 {
    max-width: 70%;
}

.css-1xnbq41-MuiAutocomplete-root {
    margin-left: -20px !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.specilaity_btn {
    width: 255px;
    margin-left: -70px;
    text-align: center !important;
}

.MuiOutlinedInput-root .MuiAutocomplete-endAdornment,
.css-1l6di18 .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    background-color: #fff !important;
    right: 35px !important;
}


.spe .MuiStack-root.css-1jruifh-MuiStack-root {
    margin-left: -69px ;
    width: 255px;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    height: 10px;
    padding: 7.5px 4px 7.5px 5px;
}

.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 9px;
    height: 40px;
}

fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
    border-width: 0px !important;

}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
    border-width: 0px !important;
}

.MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root {
    border: 1px solid #94918f !important;
    border-radius: 5px;
    padding: 4px;
}

.MuiAutocomplete-root .Mui-focused .MuiFormControl-root .MuiInputBase-root .Mui-focused {
    border: 2px solid #94918f !important;
}



.MuiAutocomplete-root.Mui-focused .MuiFormControl-root .MuiInputBase-root.Mui-focused {
    border: 1px solid #94918f !important;
    outline: none !important;
}

.MuiAutocomplete-root.Mui-focused .css-1bp1ao6.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;


}

.spe .css-1pahdxg-control{
    border-color: hsl(0, 0%, 80%) !important;
    margin-left: -70px !important;
    width: auto !important;
    box-shadow: none !important
}

.profile-li1{
    display: none;
}


.card-border{
    border: 2px solid #ea702b;
    margin: 5px;
    height: auto;
    border-radius: 5px;
 }
 .card-border:hover {
     box-shadow: 1px 4px 9px 4px #3f278340;
 }
 .add-btn{
     position: relative;
     left: -37px;
 }
 .patient-map-name{
    height: 183px;
    background-color: #e2e2e285;
    color: #ea702b;
    font-weight: 400;
    align-items: center;
    display: grid;
 }
 .patient-map-app{
     align-items: center;
     display: grid;
     text-align: center;
     height: 183px;
 }
 
 
 .card-border{
    border: 2px solid #3f2783;
    margin: 5px;
    height: auto;
    border-radius: 5px;
 }
 .card-border:hover {
     box-shadow: 1px 4px 9px 4px #3f278340;
 }
 .add-btn{
     position: relative;
     left: -37px;
 }
 .patient-map-name{
     height: 183px;
     background-color: #3f27837a;
     color: #fff;
     font-weight: 600;
     align-items: center;
     display: grid;
 }
 .patient-map-app{
     align-items: center;
     display: grid;
     text-align: center;
     height: 183px;
 }
 
 
 
 .card-borderss{
     border: 2px solid rgb(6, 6, 6); /* Replace "red" with your desired border color */
 
     display: flex; /* Add flex display */
     justify-content: center; /* Center the content horizontally */
     align-items: center; /* Center the content vertically */
     /* You can also add more styles to customize the appearance of the cards */
   }
   .patient_imgS:hover {
     transform: scale(1.1);
  
   }
   .patient_imgSS:hover {
     border: 2px solid #ea702b4d;
     background-color: #cccccc36;
   }
   .patient_imgS {
     transition: all 0.2s ease-in;
   }