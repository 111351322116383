.doctor_roles_map_screen {
    max-width: 480px;
    margin: 0 auto;
}
.doctor_roles_map_screen .button_box {
    margin-top: 40px;
}
.doctor_roles_map_screen .button_box button{
    border-radius: 50px;
}

.panel h4 {
    margin: 10px 0px;
    font-size: 18px;
    font-weight: 600;
	color: #000;
	/* margin-left: 20px;
	margin-bottom: 30px; */
}

.f_600 {
    font-weight: 600 !important;
}
.f_500 {
    font-weight: 500 !important;
}
.f_400 {
    font-weight: 400 !important;
}

.button_box {
    margin-top: 100px;
}
.button_box .next_btn {
    width: 264px !important;
    height: 73px !important;
    font-size: 24px !important;
    line-height: 65px !important;
    border-radius: 50px !important;
}
.button_box .next_btn span {
    margin-left: 10px;
    -webkit-transition: all linear 0.3s !important;
    -moz-transition: all linear 0.3s !important;
    -ms-transition: all linear 0.3s !important;
    -o-transition: all linear 0.3s !important;
    transition: all linear 0.3s !important;
}
.button_box .next_btn span img:first-child {
    display: none !important;
}
.button_box .next_btn:hover span img:first-child {
    display: inline-block !important;
}
.button_box .next_btn:hover span img:last-child {
    display: none !important;
}

.top__headerss {
    min-height: 72px !important;
    max-height: 100px !important;
    width: 100% !important;
    /* position: fixed !important; */
    background: #ffffff !important;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-content: center !important;
    justify-content: space-between !important;
    align-items: center !important;
    z-index: 2 !important;
    background-color: #f3f5f7 !important;
}
.leftLogo {
    margin: 0 22px !important;
    display: inline-block !important;
    width: 160px !important;
    height: auto !important;
}
.leftLogo img{
    max-width: 100%;
}
.top__header .head_left_arrows{
    display: inline-block;
}
.top__header .nav_inner_right {
    -webkit-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    margin-bottom: 0;
    /* margin-left: 130px; */
    border: none;
    min-height: 73px;
    border-radius: 0;
}

.right_user_menu{
    margin-right: 24px;
}
.right_user_menu ul{
    margin-left: 0;
    margin-bottom: 0;
}
.right_user_menu ul li{
    list-style-type: none;
}
.right_user_menu ul .use_lett{
    margin-top: 20px;
}
.right_user_menu ul .chat-menu {
    margin-right: 34px;
}
.right_user_menu ul .chat-menu img{
    margin-top: -4px;
}

.btn_outline {
    background: transparent;
    border: 2px solid #ea702b ;
    border-radius: 50px;
    color: #ea702b ;
    font-weight: 400;
    height: 40px;
    line-height: 38px;
    padding: 0 22px;
}
.btn_fill {
    background: #ea702b;
    border: 2px solid #e0e0e0;
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    line-height: 38px;
    padding: 0 22px;
    width: 140px;
}
.btn_fill:focus, .btn_fill:hover {
    background: transparent;
    border: 2px solid #e0e0e0;
    color: #000000;
    outline: none;
    text-decoration: none;
}

.form-control, .form-select, .form-select-op {
    /* border: 2px solid rgba(63,39,131,.7); */
    /* border-radius: 20px; */
    box-shadow: none;
    /* color: #3f2783; */
    /* height: 40px; */
    /* margin-bottom: 12px; */
    width: 100%;
}

.br_10, .br_10 .form-select {
    border-radius: 10px;
}

.form-label, label {
    /* color: #ea702b; */
    font-weight: 500;
    margin-bottom: 15px;
}

.mb_60 {
    margin-bottom: 60px;
}
.mt_40 {
    margin-top: 40px;
}

.text-danger {
    display: inline-block;
    margin-bottom: 10px;
    margin-left: 10px;
}
.text-danger {
    color: red!important;
}

/* mobile view  */
@media (min-width: 310px) and (max-width: 450px) {
    .doctor_roles_map_screen {
        max-width: 480px;
        margin: 0 auto;
       
    }
    .w-25{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
